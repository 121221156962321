<template>
  <div v-if="currentPatient || newPatientMode">
  <el-dialog
      :title="localPatientFullInfo"
      v-if="localPatient"
      v-model="showForm"
      width="80%"
      :before-close="closeDialog">

    <el-dialog
      title="Krebsregister: Ergebnis der Erstellung"
      v-model="cancerRegistryShowResult"
      width="70%">
      Es wurden <b>{{cancerRegistryResult.length}}</b> Meldungen generiert.
      <span v-if="cancerRegistryResultCountErrors > 0">Davon sind <b>{{cancerRegistryResultCountErrors}}</b> fehlerhaft.<br>Im Krebsregister-Modul gibt es die Möglichkeit die Meldungen zu bearbeiten und die Fehler zu korrigieren.</span>
      <hr/>
      <el-button @click="$router.push({ name: 'cancerregistry' } )">Zur Krebsregister Übersicht</el-button>
    </el-dialog>

    <el-tabs>
      <el-tab-pane label="Stammdaten">

        <el-row class="mt-4">
          <el-col :span="5">
            <h6>Vorname*</h6>
            <el-input
              placeholder="Vorname"
              v-model="localPatient.firstname"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Nachname*</h6>
            <el-input
              placeholder="Nachname"
              v-model="localPatient.name"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Geschlecht*</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.sex">
              <el-option label="Weiblich" value="w"></el-option>
              <el-option label="Männlich" value="m"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Geburtstag*</h6>
            <el-date-picker
              class="w-100"
              v-model="localPatient.birthday"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col v-if="!newPatientMode" :span="11">
            <h6>Behandler*</h6>
            <el-select size="medium" class="w-100" v-model="localPatient.practitioner" filterable>
              <el-option :value="null">-- Kein Behandler ausgewählt --</el-option>
              <el-option
                v-for="item in practitioner"
                :key="item.id"
                :label="item.title"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
          <el-col v-if="newPatientMode" :span="5">
            <h6>Patienten-ID*</h6>
            <el-input v-model="localPatient.id"></el-input>
          </el-col>
          <el-col  v-if="newPatientMode"  :span="5" :offset="1">
            <h6>Behandler*</h6>
            <el-select size="medium" class="w-100" v-model="localPatient.practitioner" filterable>
              <el-option :value="null">-- Kein Behandler ausgewählt --</el-option>
              <el-option
                v-for="item in practitioner"
                :key="item.id"
                :label="item.title"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Behandlungsort</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.therapylocation">
              <el-option :value="null">-- Kein Behandlungsort --</el-option>
              <el-option
                v-for="item in therapylocations"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Tumorzentrum</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.tumorcenter">
              <el-option :value="null">-- Kein Tumorzentrum --</el-option>
              <el-option
                v-for="item in tumorcenter"
                :key="item.id"
                :label="item.title"
                :value="item.title">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="5">
            <h6>KK Status</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.insurance_status">
              <el-option :value="null">-- Kein KK Status --</el-option>
              <el-option label="GKV" value="GKV"></el-option>
              <el-option label="PKV" value="PKV"></el-option>
              <el-option label="Anderer Kostenträger" value="Anderer"></el-option>
              <el-option label="Nicht versichert" value="NA"></el-option>
            </el-select>

            <div class="mt-3" v-if="localPatient.insurance_status == 'Anderer'">
              <h6>KK Ersatzcode</h6>
              <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.insurance_alternative_status">
                <el-option :value="null">-- Kein Ersatzcode --</el-option>
                <el-option label="Selbstzahler" value="970000011"></el-option>
                <el-option label="Kostenträger ohne IK-Nummer" value="970001001"></el-option>
                <el-option label="Asylbewerber/Geflüchteter" value="970100001"></el-option>
                <el-option label="Privatversichert Kasse unbekannt" value="970000022"></el-option>
              </el-select>
            </div>

          </el-col>

          <el-col :span="5" :offset="1">
            <h6>KK Mitgliedsnummer</h6>
            <el-input v-model="localPatient.insurance_member_number"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>KK IKNR</h6>
            <el-input v-model="localPatient.insurance_iknr"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Zertifizierung</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.certification">
              <el-option :value="null">-- Keine Zertifizierung --</el-option>
              <el-option :value="1" label="Zentrumsfall/Primärfall"></el-option>
              <el-option :value="2" label="Zentrumsfall/kein Primärfall"></el-option>
              <el-option :value="3" label="Kein Zentrumsfall"></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="5">
            <h6>Behandlungsende (Datum)</h6>
            <el-date-picker
              class="w-100"
              v-model="localPatient.end_of_treatment_date"
              type="date"
              value-format="YYYY-MM-DD"
              format="DD.MM.YYYY"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Behandlungsende (Status)</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.end_of_treatment">
              <el-option :value="null">-- Kein Behandlungsende --</el-option>
              <el-option :value="1" label="Ende der Behandlung"></el-option>
              <el-option :value="2" label="Lost to follow up"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Standort*</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.subsidiary_id">
              <el-option
                v-for="item in subsidiaries"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

      </el-tab-pane>

      <el-tab-pane label="Namenszusätze">

        <el-row class="mt-4">
          <el-col :span="5">
            <h6>Titel</h6>
            <el-input
              placeholder="Titel"
              v-model="localPatient.extra_names.title"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Namenszusatz</h6>
            <el-input
              placeholder="Namenszusatz"
              v-model="localPatient.extra_names.suffix"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Namensvorsatz</h6>
            <el-input
              placeholder="Namensvorsatz"
              v-model="localPatient.extra_names.prefix"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Weitere Vornamen</h6>
            <el-input
              placeholder="Vornamen"
              v-model="localPatient.extra_names.firstnames"></el-input>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="5">
            <h6>Geburtsname</h6>
            <el-input
              placeholder="Geburtsname"
              v-model="localPatient.extra_names.birthname"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Früherer Nachname</h6>
            <el-input
              placeholder="Früherer Nachname"
              v-model="localPatient.extra_names.former_name"></el-input>
          </el-col>
        </el-row>


      </el-tab-pane>

      <el-tab-pane label="Adresse">

        <el-row class="mt-4">
          <el-col :span="7">
            <h6>Straße</h6>
            <el-input
              placeholder="Titel"
              v-model="localPatient.address.street"></el-input>
          </el-col>
          <el-col :span="3" :offset="1">
            <h6>Hausnummer</h6>
            <el-input
              placeholder="Hausnummer"
              v-model="localPatient.address.street_no"></el-input>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="3">
            <h6>Postleitzahl</h6>
            <el-input
              placeholder="Postleitzahl"
              v-model="localPatient.address.zip"></el-input>
          </el-col>
          <el-col :span="7" :offset="1">
            <h6>Ort</h6>
            <el-input
              placeholder="Ort"
              v-model="localPatient.address.city"></el-input>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="11">
            <h6>Land</h6>
            <country-select v-model="localPatient.address.country"/>
          </el-col>
        </el-row>

      </el-tab-pane>

      <el-tab-pane label="Tod">

        <el-row class="mt-4">
          <el-col :span="5">
            <h6>Todestag</h6>
            <el-date-picker
              class="w-100"
              v-model="localPatient.deathday"
              type="date"
              value-format="YYYY-MM-DD"
              format="DD.MM.YYYY"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Todesursache</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.deathcause">
              <el-option :value="null">-- Keine Ursache gewählt --</el-option>
              <el-option value="tumorbedingt" label="Tumorbedingt"></el-option>
              <el-option value="begleiterkrankung" label="Begleiterkrankung"></el-option>
              <el-option value="komplikation" label="Komplikation/Therapiebedingt"></el-option>
              <el-option value="unfall" label="Unfall"></el-option>
              <el-option value="unbekannt" label="Unbekannt"></el-option>
              <el-option value="sonstiges" label="Sonstiges"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>ICD der Todesursache</h6>
            <el-input
              placeholder="ICD Code"
              v-model="localPatient.deathcause_icd"></el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <h6>Sterbeort</h6>
            <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="localPatient.deathlocation">
              <el-option :value="null">-- Keinen Ort gewählt --</el-option>
              <el-option value="zuhause" label="Zuhause"></el-option>
              <el-option value="krankenhaus" label="Krankenhaus"></el-option>
              <el-option value="hospiz" label="Hospiz"></el-option>
              <el-option value="sonstiges" label="Sonstiges"></el-option>
              <el-option value="unbekannt" label="Unbekannt"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="24">
            <h6>Bemerkung zur Todesursache</h6>
            <el-input type="textarea" v-model="localPatient.deathdescription"></el-input>
          </el-col>
        </el-row>

      </el-tab-pane>
      <el-tab-pane label="Bemerkungen">
        <el-input class="mt-4" type="textarea" v-model="localPatient.comments" :rows="10"></el-input>
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <el-row>
        <el-col :span="14" class="text-left">
          <el-popconfirm
              v-if="!newPatientMode"
              title="Sie möchten diesen Patienten löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="deleteLocalPatient">
            <template #reference>
              <el-button type="danger">Löschen</el-button>
            </template>
          </el-popconfirm>

          <el-popover
            placement="top-start"
            trigger="hover"
            :width="400"
            v-if="!newPatientMode"
          >
            <template #reference>
              <el-button type="secondary" @click="onPatientExport">Export</el-button>
            </template>
            Es werden bis auf Vorname, Nachname und Geburtsdatum alle Daten dieses Patienten in eine Datei exportiert. Statt dem Geburtsdatum wird das aktuelle Alter ausgegeben. Diese Datei kann in eine andere DACY Instanz eingelesen werden.
          </el-popover>

          <el-popover
            placement="top-start"
            trigger="hover"
            :width="400"
            v-if="!newPatientMode"
          >
            <template #reference>
              <el-button type="secondary" @click="onPatientCancerRegistry" :disabled="cancerRegistryLoading"><i class="el-icon el-icon-loading" v-if="cancerRegistryLoading"></i> Krebsregister</el-button>
            </template>
            Es werden alle notwendigen und noch nicht zum Krebsregister gesendeten Meldungen erstellt
          </el-popover>

          <el-button type="primary" v-if="!newPatientMode" @click="newPatientMode = true; localPatient = { id: null }">Neuer Patient</el-button>
        </el-col>
        <el-col :span="10" class="dialog-footer text-right">
            <el-button @click="closeDialog">Schließen</el-button>
            <el-button type="primary" @click="savePatientForm()" :disabled="patientLoading">Patient speichern</el-button>
        </el-col>
      </el-row>

    </template>
  </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import CountrySelect from "../parts/CountrySelect.vue";
import _ from "lodash";

export default {
  name: "PatientForm",
  props: ['modelValue', 'show', 'mode', 'routerreturnname'],
  components: {CountrySelect},
  data() {
    return {
      showForm: false,
      localPatient: null,
      newPatientMode: false,
      localRouterReturnName: 'dashboard',
      patientLoading: false,
      cancerRegistryLoading: false,
      cancerRegistryResult: [],
      cancerRegistryShowResult: false
    }
  },
  mounted: async function () {
    this.showForm = this.modelValue
    this.newPatientMode = (this.mode == 'new') ? true : false
    this.localRouterReturnName = this.routerreturnname ? this.routerreturnname : 'dashboard'
    await this.getPractitioner()
    await this.getDeathcauses()
    await this.getTherapyLocations()
    await this.getTumorCenter()
    await this.getSubsidiaries()
  },
  computed: {
    ...mapGetters('config', ['modules_enabled']),
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapGetters('practitioner', ['practitioner']),
    ...mapGetters('deathcauses', ['deathcauses']),
    ...mapGetters('tumorcenter', ['tumorcenter']),
    ...mapGetters('therapylocations', ['therapylocations']),
    ...mapGetters('subsidiaries', ['subsidiaries']),
    localPatientFullInfo: function() {
     let info = this.newPatientMode ? 'Neue/r Patient/in' : this.fullInfo + ' - DACY ID: #'+this.currentPatient.id_pat
     if(this.currentPatient && this.currentPatient.edids && this.currentPatient.edids.length > 0) {
       info += ' - EDID: #' + this.currentPatient.edids[0].edid
     }

      return info
    },
    cancerRegistryResultCountErrors: function() {
      return _.filter(this.cancerRegistryResult, (r) => r.status === 'invalid').length
    }
},
  watch: {
    modelValue: function (val) {
      this.showForm = val
      if(this.currentPatient) {
        this.localPatient = JSON.parse(JSON.stringify(this.currentPatient))
        this.newPatientMode = false
      } else {
        this.localPatient = {
          extra_names: {},
          address: {
            country: 'DE'
          },
          certification: 3
        }
      }
      if(!this.localPatient.subsidiary_id && this.subsidiaries && this.subsidiaries.length === 1) this.localPatient.subsidiary_id = this.subsidiaries[0].id
    },
    currentPatient: function(patient) {
      if(patient === null) return
      this.localPatient = JSON.parse(JSON.stringify(patient))
      if(!this.localPatient.subsidiary_id && this.subsidiaries && this.subsidiaries.length === 1) this.localPatient.subsidiary_id = this.subsidiaries[0].id
    },
    mode: function(val) {
      this.newPatientMode = val == 'new' ? true : false
    },
    'routerreturnname': function(router_name) {
      if(router_name) this.localRouterReturnName = router_name
      console.log("routername", this.localRouterReturnName)
    }
  },
  methods: {
    ...mapActions('cancerregistry', ['generateDeathXml', 'generateAllXml']),
    ...mapActions('practitioner', ['getPractitioner']),
    ...mapActions('patients', ['updatePatient', 'deletePatient', 'saveNewPatient', 'setCurrentPatient', 'resetCurrentPatient', 'reloadCurrentPatient']),
    ...mapActions('deathcauses', ['getDeathcauses']),
    ...mapActions('tumorcenter', ['getTumorCenter']),
    ...mapActions('therapylocations', ['getTherapyLocations']),
    ...mapActions('subsidiaries', ['getSubsidiaries']),
    closeDialog() {
      this.$emit('update:modelValue', false)
    },
    async savePatientForm() {

      this.patientLoading = true

      if(!this.localPatient.id || !this.localPatient.firstname || !this.localPatient.name || !this.localPatient.sex || !this.localPatient.birthday || !this.localPatient.subsidiary_id || !this.localPatient.practitioner) {
        this.$message({
          message: "Bitte alle benötigten Felder (mit Stern markiert) ausfüllen",
          type: 'error'
        });
      }
      else {

        let result
        if(this.newPatientMode) result = await this.saveNewPatient(this.localPatient)
        else result = await this.updatePatient(this.localPatient)

        if(result) {

          this.reloadCurrentPatient()

          this.$message({
            message: "Patient erfolgreich gespeichert",
            type: 'success'
          });

          if(this.modules_enabled.cancerregistry && this.localPatient.deathday !== null && this.localPatient.deathday !== undefined) {

              this.$messageBox.confirm('Soll der Tod dieses Patienten dem Krebsregister gemeldet werden?', 'Krebsregister', { confirmButtonText: 'Ja', cancelButtonText: 'Nein', type: 'warning' }).then(async () => {
                const xmlResult = await this.generateDeathXml(this.localPatient)
                this.$notify({
                  message: 'Meldung an das Krebsregister wurde ' + ( xmlResult ? '' : 'nicht ' ) + 'generiert',
                  type: (xmlResult ? 'success' : 'error'),
                  position: 'bottom-left'
                });
              })

          }

          if(this.newPatientMode) {
            this.showForm = false
            this.setCurrentPatient(this.localPatient.id)
            this.localPatient = null
            this.$emit('update:modelValue', false)
            this.$router.push({ name: this.localRouterReturnName})
          }
        }
        else {
          this.$message({
            message: "Patient konnte nicht gespeichert werden, ID bereits vergeben? Internetverbindung muss beim Anlegen eines Patienten aktiv sein.",
            type: 'error'
          });
        }

      }

      this.patientLoading = false

    },
    async deleteLocalPatient() {
      const result = await this.deletePatient(this.localPatient)
      if(result) {
        this.newPatientMode = false
        this.showForm = false
        this.$emit('update:modelValue', false)
        this.localPatient = null
        this.resetCurrentPatient()
        this.$router.push({ name: this.localRouterReturnName})
      }
    },
    async onPatientExport() {

        let edid_suffix = ''
        if(this.currentPatient.edids && this.currentPatient.edids.length > 0) {
          edid_suffix = '_' + this.currentPatient.edids[0].edid
        }

        const exportData = (await axios.get('/export/patients/json/' + this.localPatient.id)).data
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(exportData)]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dacy_export_patient' + edid_suffix + '.json');
        document.body.appendChild(link);
        link.click();

    },
    async onPatientCancerRegistry() {
      this.cancerRegistryLoading = true
      this.cancerRegistryResult = await this.generateAllXml(this.localPatient.id)
      this.cancerRegistryLoading = false
      this.cancerRegistryShowResult =  true

    }
}

}
</script>
<style scoped>
</style>
